<template>
  <!-- Content Row -->
  <ValidationObserver v-slot="{ invalid, handleSubmit }">
    <form ref="userForm" @submit.prevent="handleSubmit(saveUserForm)">
      <div class="vx-row">

        <div class="vx-col md:w-1/2 w-full">
          <ValidationProvider :name="$t('administration.userform.form.input.username')" rules="required" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.username')" v-model="userLocal.username" name="username" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider :name="$t('administration.userform.form.input.firstname')" rules="required" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.firstname')" v-model="userLocal.firstname" name="firstname" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider :name="$t('administration.userform.form.input.lastname')" rules="required" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.lastname')" v-model="userLocal.lastname" name="lastname" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <ValidationProvider :name="$t('administration.userform.form.input.email')" rules="required|email" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.email')" v-model="userLocal.email" name="email" />
            <span class="error-class" v-for="error in errors" :key="error">{{ error }}</span>
          </ValidationProvider>

          <ValidationProvider :name="$t('administration.userform.form.input.group')" rules="required" v-slot="{ errors }">
            <vs-select class="w-full mt-4" :label="$t('administration.userform.form.input.group')" v-model="userLocal.groups" multiple autocomplete>
              <vs-select-item :key="item.id" :value="'/groups/' + item.id" :text="item.name" v-for="item in groupList" />
            </vs-select>
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button button="submit" class="ml-auto mt-2" :disabled="invalid">{{ $t('administration.userform.form.input.submit') }}</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetUserFrom">{{ $t('administration.userform.form.input.reset') }}</vs-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import moduleGroupMixin from '@/store/group/moduleGroupMixin'

const cloneDeep = require('lodash.clonedeep')

export default {
  name: 'UserForm',
  mixins: [moduleGroupMixin],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userLocal: cloneDeep(this.user)
    }
  },
  computed: {
    groupList () {
      return this.$store.state.group.groups
    }
  },
  methods: {
    saveUserForm () {
      if (this.user.id) {
        this.$emit('update:user', this.userLocal)
      } else {
        this.$emit('new:user', this.userLocal)
      }
    },
    resetUserFrom () {
      this.$refs.userForm.reset()
      this.userLocal = cloneDeep(this.user)
    }
  },
  watch: {
    'userLocal.firstname' (newFirstname) {
      this.$emit('update:user:firstname', newFirstname)
    },
    'userLocal.lastname' (newLastname) {
      this.$emit('update:user:lastname', newLastname)
    }
  },
  created () {
    // Register Module UserManagement Module
    this.$store.dispatch('group/fetchGroups').catch(err => { console.error(err) }) // eslint-disable-line no-console
  }
}
</script>

<style scoped>

</style>
