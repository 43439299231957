import moduleGroup from './moduleGroup'

export default {
  created () {
    if (!moduleGroup.isRegistered) {
      this.$store.registerModule('group', moduleGroup)
      moduleGroup.isRegistered = true
    }
  }
}
