import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    groups: []
  },
  mutations: {
    SET_GROUPS (state, groups) {
      state.groups = groups
    },
    ADD_GROUP (state, group) {
      state.groups.push(group)
    },
    REMOVE_GROUP (state, groupId) {
      const groupIndex = state.groups.findIndex((g) => g.id === groupId)
      state.groups.splice(groupIndex, 1)
    },
    UPDATE_GROUP (state, group) {
      const groupIndex = state.groups.findIndex((g) => g.id === group.id)
      state.groups[groupIndex] = group
    }
  },
  actions: {
    addGroup ({ commit }, group) {
      return new Promise((resolve, reject) => {
        axios.post('/groups', group)
          .then((response) => {
            commit('ADD_GROUP', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    updateGroup ({ commit }, group) {
      return new Promise((resolve, reject) => {
        axios.put(`/groups/${group.id}`, group)
          .then((response) => {
            commit('UPDATE_GROUP', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchGroups ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/groups?pagination=false')
          .then((response) => {
            commit('SET_GROUPS', response.data['hydra:member'])
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchGroup (context, groupId) {
      return new Promise((resolve, reject) => {
        axios.get(`/groups/${groupId}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    removeGroup ({ commit }, groupId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/groups/${groupId}`)
          .then((response) => {
            commit('REMOVE_GROUP', groupId)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {}
}
