var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"userForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveUserForm)}}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.userform.form.input.username'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('administration.userform.form.input.username'),"name":"username"},model:{value:(_vm.userLocal.username),callback:function ($$v) {_vm.$set(_vm.userLocal, "username", $$v)},expression:"userLocal.username"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.userform.form.input.firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('administration.userform.form.input.firstname'),"name":"firstname"},model:{value:(_vm.userLocal.firstname),callback:function ($$v) {_vm.$set(_vm.userLocal, "firstname", $$v)},expression:"userLocal.firstname"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.userform.form.input.lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('administration.userform.form.input.lastname'),"name":"lastname"},model:{value:(_vm.userLocal.lastname),callback:function ($$v) {_vm.$set(_vm.userLocal, "lastname", $$v)},expression:"userLocal.lastname"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.userform.form.input.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('administration.userform.form.input.email'),"name":"email"},model:{value:(_vm.userLocal.email),callback:function ($$v) {_vm.$set(_vm.userLocal, "email", $$v)},expression:"userLocal.email"}}),_vm._l((errors),function(error){return _c('span',{key:error,staticClass:"error-class"},[_vm._v(_vm._s(error))])})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('administration.userform.form.input.group'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('administration.userform.form.input.group'),"multiple":"","autocomplete":""},model:{value:(_vm.userLocal.groups),callback:function ($$v) {_vm.$set(_vm.userLocal, "groups", $$v)},expression:"userLocal.groups"}},_vm._l((_vm.groupList),function(item){return _c('vs-select-item',{key:item.id,attrs:{"value":'/groups/' + item.id,"text":item.name}})}),1),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"mt-8 flex flex-wrap items-center justify-end"},[_c('vs-button',{staticClass:"ml-auto mt-2",attrs:{"button":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('administration.userform.form.input.submit')))]),_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"type":"border","color":"warning"},on:{"click":_vm.resetUserFrom}},[_vm._v(_vm._s(_vm.$t('administration.userform.form.input.reset')))])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }